import { DefaultTheme } from "styled-components";

export const theme: DefaultTheme = {
  colors: {
    background: "#4CB050",
    primary: "#0070f3",
    secondary: "#1a1a1a",
    text: "#333333",
    zinc: {
      50: "#fafafa",
      100: "#f4f4f5",
      200: "#e4e4e7",
      300: "#d4d4d8",
      400: "#a1a1aa",
      500: "#71717a",
      600: "#52525b",
      700: "#3f3f46",
      800: "#27272a",
      900: "#18181b",
    },
    green: {
      50: "#B3EFBB",
      100: "#95E09D",
      200: "#78D080",
      300: "#71C179",
      400: "#6AB271",
      500: "#4D8D50",
      600: "#3E7640",
      700: "#306030",
      800: "#214920",
      900: "#041C00",
    },
    red: {
      50: "#FBC4C2",
      100: "#F79997",
      200: "#F36D6C",
      300: "#EF4141",
      400: "#D5383A",
      500: "#BA2E32",
      600: "#A0252B",
      700: "#861C23",
      800: "#6C131C",
      900: "#510914",
    },
    blue: {
      50: "#C6D3F0",
      100: "#9BB2E2",
      200: "#6F92D3",
      300: "#4471C5",
      400: "#1851B6",
      500: "#14489F",
      600: "#103E88",
      700: "#0C3471",
      800: "#1E3346",
      900: "#00182C",
    },
  },
  fonts: {
    primary: '"Noto Serif Georgian", serif',
    secondary: '"Arial", sans-serif',
  },
  sizes: {
    mobile: "480px",
    tablet: "768px",
    laptop: "1024px",
    desktop: "1200px",
  },
  platform: {
    mobile: `@media (max-width: 480px)`,
    tablet: `@media (max-width: 768px)`,
    laptop: `@media (max-width: 1024px)`,
    desktop: `@media (min-width: 1200px)`,
  },
};
