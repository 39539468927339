import React from "react";
import styled from "styled-components";

interface Props {
  className: any;
}

const StyledHandsicon = styled.svg`
  & .path {
    stroke: ${(props) => props.theme.colors.background};
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2.50441;
  }
`;

export const Handsicon = ({ className }: Props): JSX.Element => {
  return (
    <StyledHandsicon
      className={`handsicon ${className}`}
      fill="none"
      height="40"
      viewBox="0 0 40 40"
      width="40"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M37.641 17.9815L33.7747 19.9067L28.7656 10.3277L32.6789 8.37119C32.9697 8.22296 33.3074 8.19572 33.6182 8.29542C33.929 8.39511 34.1878 8.61364 34.3382 8.90336L38.1889 16.2754C38.2669 16.423 38.3147 16.5847 38.3294 16.751C38.3441 16.9173 38.3253 17.0849 38.2743 17.2438C38.2232 17.4028 38.1409 17.55 38.0322 17.6766C37.9234 17.8033 37.7904 17.907 37.641 17.9815Z"
      />
      <path
        className="path"
        d="M6.22475 19.7503L2.3584 17.8095C2.20953 17.7365 2.07686 17.6344 1.96826 17.5091C1.85966 17.3839 1.77736 17.2381 1.72625 17.0804C1.67514 16.9227 1.65626 16.7563 1.67074 16.5912C1.68522 16.426 1.73277 16.2655 1.81054 16.1191L5.66123 8.747C5.81187 8.45749 6.06959 8.23822 6.3795 8.13589C6.68942 8.03357 7.02705 8.05628 7.32047 8.19918L11.2338 10.1557L6.22475 19.7503Z"
      />
      <path
        className="path"
        d="M33.7723 19.9065L31.2678 22.8491L25.5074 28.609C25.3504 28.7558 25.1602 28.8624 24.953 28.9196C24.7458 28.9769 24.5279 28.9831 24.3178 28.9377L15.2389 26.6682C15.0731 26.6219 14.9185 26.542 14.785 26.4334L6.22266 19.75"
      />
      <path
        className="path"
        d="M31.2692 22.8492L24.3818 17.8406L22.3781 19.3432C21.5101 19.991 20.4559 20.341 19.3727 20.341C18.2895 20.341 17.2354 19.991 16.3673 19.3432L15.522 18.7014C15.3791 18.5928 15.2609 18.455 15.1752 18.2972C15.0895 18.1394 15.0383 17.9653 15.025 17.7862C15.0117 17.6072 15.0365 17.4273 15.0979 17.2586C15.1593 17.0899 15.2559 16.9362 15.3812 16.8076L21.5172 10.6876C21.6325 10.573 21.7692 10.4821 21.9196 10.4203C22.0701 10.3586 22.2312 10.3271 22.3938 10.3276H28.7647"
      />
      <path
        className="path"
        d="M11.3281 10.1556L19.3582 7.80779C19.6435 7.72598 19.9487 7.74816 20.2191 7.8704L25.6352 10.3278"
      />
      <path
        className="path"
        d="M17.4963 32.2402L12.7847 31.0507C12.5927 31.0073 12.4149 30.9157 12.2681 30.7846L8.73047 27.7168"
      />
    </StyledHandsicon>
  );
};
